.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.marker {
  background-image: url("../../assets/mapIcon.svg");
  /* background-size: 30% auto; */
  position: absolute;
  cursor: pointer;
  width: 49px;
  height: 64px;
  top: -64px;
  left: -24.5px;
  transform-origin: 24.5px 64px;
  margin: 0;
  padding: 0; }
