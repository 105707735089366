* {
    box-sizing: border-box;
  }
  
  /* Slideshow container */
  .slider-container {
    position: relative;
    margin: 50px auto 20px auto;
    width: 600px;
    height: 300px;
  }
  
  /* Style each slide */
  .slide {
    display: none;
    width: 100%;
    height: 100%;
  }
  
  .photo {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border: none;
  }
  
  /* Position the "next" button to the right */
  .next {
    right: 0;
  }
  
  /* On hover, add a semi-transparent black background */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Caption text */
  .caption {
    position: absolute;
    bottom: 8px;
    width: 100%;
    padding: 8px 12px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
  
  /* The dots indicator */
  .dots {
    display: flex;
    justify-content: center;
  }
  
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    transition: 0.5s;
  }
  
  /* .active,
  .dot:hover {
    background-color: gray;
  } */
  
  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @keyframes fade {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }