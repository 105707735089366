/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600"); */
body {
  padding: 0;
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.timelineOrigo {
  
  position: relative;
  padding: 5px 0px;
  box-sizing: border-box;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width:"100%";
  
}
.timelineOrigo:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}
.timelineOrigo ul {

  padding: 0;
  margin: 0;
}
.timelineOrigo ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.timelineOrigo ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.timelineOrigo ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}
.contentOrigo {
  padding-bottom: 5px;
}
.timelineOrigo ul li:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  right: -6px;
  background: rgba(1, 106, 112, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(1, 106, 112, 0.2);
}
.timelineOrigo ul li:nth-child(even):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: rgba(1, 106, 112, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(1, 106, 112, 0.2);
}

.timelineOrigo ul li h3 {
  padding: 0;
  margin: 0;
  /* color: rgba(27, 107, 147,1);1d2922 */
  color: rgba(1, 106, 112, 1);
  /* color:#025955; */
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
}
.timelineOrigo ul li p {
  margin: 5px 0 0;
  padding: 0;
  color: #0e364a;
  font-size: 12px;
  line-height: 1.5;
}
.timelineOrigo ul li .timeOrigo h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.timelineOrigo ul li:nth-child(odd) .timeOrigo {
  position: absolute;
  top: 12px;
  right: -150px;
  margin: 0;
  padding: 2px 16px;
  background:#362706;
  color: #FFFFFF;
  border-radius: 18px;
  box-shadow: 0 0 0 2px #362706;
}
.timelineOrigo ul li:nth-child(even) .timeOrigo {
  position: absolute;
  top: 12px;
  left: -150px;
  margin: 0;
  padding: 2px 16px;
  background: #362706;
  color: #FFFFFF;
  border-radius: 18px;
  box-shadow: 0 0 0 2px #362706;
}


@media (max-width: 1000px) {
  .timelineOrigo {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .timelineOrigo {
    width: 100%;
    padding-bottom: 0;
  }
  h1 {
    font-size: 40px;
    text-align: center;
  }
  .timelineOrigo:before {
    left: 20px;
    height: 100%;
  }
  .timelineOrigo ul li:nth-child(odd),
  .timelineOrigo ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }
  .timelineOrigo ul li:nth-child(odd):before,
  .timelineOrigo ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }
  .timelineOrigo ul li:nth-child(odd) .timeOrigo,
  .timelineOrigo ul li:nth-child(even) .timeOrigo {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}
